<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="1100px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer> </v-spacer>
            <v-radio-group v-model="service" row mandatory dense>
              <v-radio label="Produits" :value="0"></v-radio>
              <v-radio label="Service" :value="1"></v-radio>
            </v-radio-group>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="8" v-if="cmd.source_id == '1'">
                  <v-autocomplete
                    v-model="editedItem.dem_det_id"
                    :item-value="'id'"
                    :items="products_list"
                    :filter="customFilter"
                    label="Produit"
                    :rules="[(v) => !!v || 'Produit obligatoire']"
                    dense
                    @change="produit_change"
                    :loading="loading"
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        item.no_da +
                        "-" +
                        (item.code ? item.code + "-" : "") +
                        item.label
                      }}
                    </template>
                    <template v-slot:item="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        item.no_da +
                        "-" +
                        (item.code ? item.code + "-" : "") +
                        item.label +
                        "-" +
                        item.qte_reste +
                        " " +
                        item.unit_name
                      }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="10"
                  v-if="cmd.source_id == '0' && allproduct"
                >
                  <cursorselect
                    :Qsearch="products_cursor"
                    :Qresp="'products_cursor'"
                    :value="editedItem.produit_id"
                    :text_fields="['code', 'label']"
                    :variableadd="variableadd"
                    :whereadd="whereadd"
                    @change="produit_change"
                    :label="'Produit'"
                    :key="cs"
                  >
                  </cursorselect>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="10"
                  v-if="cmd.source_id == '0' && !allproduct"
                >
                  <v-autocomplete
                    v-model="editedItem.produit_id"
                    :item-value="
                      allproduct || (tiertype == 1 && spec && !allproduct)
                        ? 'id'
                        : 'produit_id'
                    "
                    :items="products_list"
                    :filter="customFilter"
                    label="Produit"
                    :rules="[
                      (v) => !!v || 'Produit obligatoire',
                      (v) => cnv_ok || 'Produit introuvable dans la convention',
                    ]"
                    @change="produit_change"
                    dense
                    :loading="loading"
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        (allproduct || (tiertype == 1 && spec && !allproduct)
                          ? item.code
                          : item.product_code) +
                        "-" +
                        (item.ref ? "(" + item.ref + ")" + "-" : "") +
                        (allproduct || (tiertype == 1 && spec && !allproduct)
                          ? item.label
                          : item.product_name)
                      }}
                    </template>
                    <template v-slot:item="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      <v-list-item-content>
                        {{
                          (allproduct || (tiertype == 1 && spec && !allproduct)
                            ? item.code
                            : item.product_code) +
                          "-" +
                          (allproduct || (tiertype == 1 && spec && !allproduct)
                            ? item.label
                            : item.product_name)
                        }}
                        <v-list-item-subtitle>
                          <v-row>
                            <v-col cols="12" sm="10" md="10">
                              {{ item.ref ? "Ref. Ext. " + item.ref : "" }}
                            </v-col>
                            <v-col cols="12" sm="2" md="2">
                              <v-chip
                                small
                                :color="item.color"
                                v-if="item.statuts_name"
                              >
                                {{ item.statuts_name }}
                              </v-chip>
                            </v-col>
                          </v-row>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="1"
                  md="1"
                  v-if="
                    spec && (tiertype == 1 || tiertype == 3 || tiertype == 5)
                  "
                >
                  <v-checkbox
                    label="Tous"
                    v-model="allproduct"
                    dense
                  ></v-checkbox>
                </v-col>
                <v-col
                  cols="12"
                  sm="1"
                  md="2"
                  v-if="categorie_list.length > 1 && tiertype <= 4"
                >
                  <v-select
                    ref="categories"
                    :items="categorie_list"
                    v-model="editedItem.categorie_id"
                    item-text="categorie"
                    item-value="categorie_id"
                    label="Categorie"
                    :readonly="readonly"
                    :rules="[(v) => !!v || 'Categorie obligatoire']"
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip :color="item.color" small>
                        <span>{{ item.categorie }}</span>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-chip :color="item.color" small>
                        <span>{{ item.categorie }}</span>
                      </v-chip>
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                  v-if="
                    editedItem.produit_id && (tiertype == 2 || tiertype == 4)
                  "
                >
                  <v-autocomplete
                    v-model="editedItem.article_id"
                    :item-value="'id'"
                    :items="articles_list"
                    :filter="customFilter"
                    label="Article"
                    dense
                    clearable
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        (item.code ? item.code + "-" : "") + item.article_name
                      }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            (item.code ? item.code + '-' : '') +
                            item.article_name
                          "
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    ref="pu"
                    autocomplete="off"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.pu"
                    :readonly="!edit_price"
                    :label="
                      'Prix ' + (cmd.devise ? '(' + cmd.devise + ')' : '')
                    "
                    :rules="[
                      (v) => !!v || tiertype == 2 || 'Prix obligatoire',
                      (v) =>
                        !v || tiertype == 2 || v > 0 || 'Valeur incorrecte',
                    ]"
                    @keydown.enter="$refs.qte.focus()"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2" v-if="tiertype <= 4">
                  <v-text-field
                    autocomplete="off"
                    ref="qte"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model="editedItem.qte"
                    :readonly="readonly"
                    :label="'Quantité'"
                    :rules="[
                      (v) => !!v || 'Quantité obligatoire',
                      (v) => !v || v > 0 || 'Valeur incorrecte',
                    ]"
                    @input="qte_change"
                    @keydown.enter="save"
                    :disabled="save_disable"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                    :hint="
                      editedItem.fk_unit != editedItem.fk_unit_product &&
                      editedItem.qte2
                        ? editedItem.qte2 + ' ' + editedItem.unit
                        : ''
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="2" v-if="tiertype <= 4">
                  <v-select
                    ref="unite"
                    :items="units_list"
                    v-model="editedItem.fk_unit"
                    item-text="name"
                    item-value="id"
                    label="Unité de Mesure"
                    :readonly="readonly"
                    :rules="[(v) => !!v || 'Unité de Mesure obligatoire']"
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="1" v-if="cmd.currency_id == 114">
                  <v-text-field
                    autocomplete="off"
                    ref="tva"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.tva_tx"
                    label="TVA"
                    :readonly="
                      tiertype == 1 || tiertype == 3 || tiertype == 5
                        ? readonly ||
                          !(
                            $store.state.auth.includes('02027') ||
                            $store.state.isadmin
                          )
                        : readonly
                    "
                    :rules="[(v) => !v || v > 0 || 'Valeur incorrecte']"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2" v-if="tiertype <= 4">
                  <v-text-field
                    autocomplete="off"
                    ref="delai"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.delai"
                    label="Delai (jours)"
                    :rules="[
                      (v) =>
                        !v ||
                        (v > 0 &&
                          (cmd.delai
                            ? verif_date(cmd.date_cmd, v, cmd.date_limite)
                            : true)) ||
                        'Date Max ' + datefr(cmd.date_limite),
                    ]"
                    hide-spin-buttons
                    :hint="
                      cmd.date_limite
                        ? 'Delai Liv. : ' + datefr(cmd.date_limite)
                        : ''
                    "
                    :readonly="readonly"
                    @input="addDays"
                    @focus="$event.target.select()"
                  ></v-text-field>
                  {{ duedate ? "Avant le : " + duedate : "" }}
                </v-col>
                <v-col cols="12" sm="1" md="3" v-if="tiertype <= 4">
                  <v-select
                    ref="adresse"
                    :items="adresses"
                    v-model="editedItem.adress_id"
                    :item-text="
                      (item) =>
                        item.label +
                        '-' +
                        item.departement_name +
                        '-' +
                        item.ville_name
                    "
                    item-value="id"
                    label="Adresse de livraison"
                    :readonly="readonly"
                    dense
                    v-if="tiertype == 1 || tiertype == 3"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="1" md="12">
                  <v-text-field
                    autocomplete="off"
                    ref="description"
                    dense
                    v-model="editedItem.description"
                    label="Description Additionnelle"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="10">
                  <v-text-field
                    autocomplete="off"
                    ref="comment"
                    dense
                    v-model="editedItem.comment"
                    label="Commentaire"
                    :readonly="readonly"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="save"
              :disabled="save_disable"
              v-if="!readonly"
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_PRODUIT from "../graphql/Commande/CREATE_PRODUIT.gql";
import UPDATE_PRODUIT from "../graphql/Commande/UPDATE_PRODUIT.gql";
import PRODUCTS_CURSOR from "../graphql/Commande/PRODUCTS_CURSOR.gql";

import { spec } from "print/data.js";
import { formatAsCurrency } from "@/utils/functions.js";

export default {
  components: {
    cursorselect: () => import("./CursorSelect.vue"),
  },
  name: "cmddetailform",
  props: {
    item: Object,
    items: Array,
    readonly: Boolean,
    showForm: Boolean,
    tiertype: Number,
    tier_type_prix: Array,
    units: Array,
    qte_prix: Array,
    products_categories: Array,
    cmd: Object,
    cnv_list: Array,
    tier_products: Array,
    price_edit: Boolean,
  },

  data: () => ({
    service: 0,
    save_disable: false,
    cnv_ok: true,
    duedate: null,
    allproduct: false,
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
    combovalue: "",
    product: {},
    template: 0,
    attributs: [],
    unfilter: false,
    prixvente: 0,
    prixachat: 0,
    produits: [],
    categorie_list: [],
    units_list: [],
    articles: [],
    pageInfo: null,
    label: null,
    cs: 0,
    label_ext: "",
    ref_ext: "",
    loading: false,
  }),

  computed: {
    initialBalanceFormatted: {
      get: function () {
        return formatAsCurrency(this.editedItem.pu, 2);
      },
      set: function (newValue) {
        this.editedItem.pu = Number(newValue.replace(/[^0-9.]/g, ""));
      },
    },
    variableadd() {
      let w = {
        Kind: 3,
        Type: this.$store.state.products_types.map((elm) => elm.id),
        statut_id: [2, 11, 12, 13],
      };
      return w;
    },
    whereadd() {
      return [{ column: "SERVICE", value: this.service }];
    },
    products_cursor() {
      return PRODUCTS_CURSOR;
    },
    adresses() {
      return this.cmd ? this.cmd.adresses : [];
    },
    edit_price() {
      let ok = false;
      if ((this.tiertype == 2 || this.tiertype == 5) && !this.prixvente)
        ok = true;
      if (
        (this.tiertype == 2 || this.tiertype == 5) &&
        this.prixvente > 0 &&
        !this.readonly &&
        this.$store.state.auth.includes("02027")
      )
        ok = true;
      if (this.tiertype == 2 || this.tiertype == 4 || this.tiertype == 6)
        ok = true;
      if (
        this.tiertype == 1 &&
        (this.$store.state.auth.includes("02027") || this.$store.state.isadmin)
      )
        ok = true;
      if (
        (this.tiertype == 1 || this.tiertype == 2) &&
        this.editedItem.contrat_id
      )
        ok = this.price_edit;
      return ok;
    },
    products_types() {
      return this.$store.state.products_types;
    },
    spec() {
      return spec;
    },

    showDetail() {
      return this.showForm;
    },

    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouvelle Ligne  "
          : "Ligne N° ".concat(parseInt(this.item.index) + 1);
      else return "";
    },
    articles_list() {
      let list = [];
      list = this.articles.filter(
        (elm) =>
          !this.items.map((elm2) => elm2.article_id).includes(elm.id) ||
          elm.id == this.editedItem.article_id
      );

      return list;
    },
    products_list() {
      let list = [];
      let l = [];
      l = !this.allproduct ? this.tier_products : [];
      if (l) list = l.filter((elm) => elm.service == this.service);

      return list;
    },
  },
  watch: {},

  created() {},
  async mounted() {
    this.allproduct = !this.spec && this.tiertype == 1;
    if (this.item) {
      this.editedItem = Object.assign({}, this.item);

      if (this.editedItem.id > 0) {
        this.service = this.editedItem.service;
        if (this.spec && this.tiertype == 1) {
          let i = this.tier_products.findIndex(
            (elm) => elm.id == this.editedItem.produit_id
          );
          if (i == -1) this.allproduct = true;
        }
        this.cs++;
        this.produit_change();
      }
      this.addDays();
    }
  },

  methods: {
    customFilter(item, queryText) {
      let textlist = [];
      ["ref", "code", "label", "product_name"].forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },

    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },

    verif_date(date1, days, date2) {
      var end = new Date(date1);
      end.setDate(end.getDate() + parseInt(days));
      var start = new Date(date2);
      return end <= start;
    },
    addDays() {
      if (this.editedItem.delai > 0) {
        let d = parseInt(this.editedItem.delai);
        var result = new Date(this.cmd.date_cmd);
        result.setDate(result.getDate() + d);
        this.duedate = result.toLocaleDateString("fr-FR", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      } else this.duedate = null;
    },
    datefr(date) {
      var result = new Date(date);
      return result.toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },

    produit_change(item, l) {
      let i;
      let list = this.products_list;
      if (l) {
        list = l;
        this.editedItem.produit_id = item;
      }
      this.editedItem.article_id = null;
      if (this.cmd.source_id == "1")
        i = list.findIndex((elm) => elm.id == this.editedItem.dem_det_id);
      else
        i = list.findIndex(
          (elm) =>
            (this.allproduct ||
            (this.tiertype == 1 && this.spec && !this.allproduct)
              ? elm.id
              : elm.produit_id) == this.editedItem.produit_id
        );
      this.cnv_ok = true;
      this.get_categories();
      if (i >= 0) {
        let produit = list[i];
        this.editedItem.service = produit.service;
        this.editedItem.fk_unit_buy = produit.fk_unit_buy;
        this.editedItem.fk_unit_product = produit.fk_unit;
        if (this.tiertype == 1 || this.tiertype == 3 || this.tiertype == 5) {
          if (this.spec) {
            let k = this.tier_type_prix.findIndex(
              (elm) =>
                elm.produit_id == this.editedItem.produit_id &&
                elm.tier_type_id == this.item.tier_type_id
            );

            if (k >= 0) {
              this.prixvente = this.tier_type_prix[k].prix;
            } else this.prixvente = produit.prixvente;
          } else this.prixvente = produit.prixvente;
          this.editedItem.pu = this.prixvente;

          //unite de mesure

          if (produit.fk_unit && this.editedItem.id < 0)
            this.editedItem.fk_unit = produit.fk_unit;
        }

        if (this.tiertype == 2 || this.tiertype == 4 || this.tiertype == 6) {
          this.editedItem.factor = produit.factor;
          this.prixachat = produit.prixachat;
          this.label_ext = produit.label_ext;
          this.ref_ext = produit.ref_ext;
          if (this.editedItem.id < 0) {
            if (this.cmd.source_id == "1")
              this.editedItem.produit_id = produit.produit_id;
            this.editedItem.pu = this.prixachat;
            if (this.cmd.source_id == "1")
              this.editedItem.qte = produit.qte_reste;
          }

          if (this.cmd.source_id == "1") this.articles = produit.articles;
          else
            this.articles = this.tier_products.filter(
              (elm) => elm.produit_id == this.editedItem.produit_id
            );
          if (this.articles.length == 1)
            this.editedItem.article_id = this.articles[0].id;
          //unite de mesure
          if (produit.fk_unit_buy && this.editedItem.id < 0)
            this.editedItem.fk_unit = produit.fk_unit_buy;
        }
        if (
          (this.tiertype == 1 || this.tiertype == 2) &&
          this.editedItem.contrat_id
        ) {
          let j = this.cnv_list.findIndex(
            (elm) => elm.produit_id == this.editedItem.produit_id
          );
          this.cnv_ok = j >= 0;
          if (this.editedItem.id < 0) this.editedItem.pu = this.cnv_list[j].pu;
        }

        if (produit.tva_tx && this.editedItem.id < 0)
          this.editedItem.tva_tx = produit.tva_tx;

        this.get_units();
        if (produit.service == 1 && !this.editedItem.tva_tx)
          this.editedItem.tva_tx =
            this.$store.state.options[0].default_service_tva;
        if (produit.service == 0 && !this.editedItem.tva_tx)
          this.editedItem.tva_tx =
            this.$store.state.options[0].default_product_tva;
        this.editedItem.unit_name = produit.unit_name;

        if (!this.editedItem.pu) this.$refs.pu.focus();
        else this.$refs.qte.focus();
      } else this.units_list = this.units;
    },
    get_units() {
      let k = this.units.findIndex(
        (elm) => elm.id == this.editedItem.fk_unit_product
      );
      if (k >= 0) {
        let c = this.units[k].category_id;
        this.units_list = this.units.filter((elm) => elm.category_id == c);
      }
      k = this.units_list.findIndex(
        (elm) => elm.id == this.editedItem.fk_unit_buy
      );
      if (k == -1) {
        let i = this.units.findIndex(
          (elm) => elm.id == this.editedItem.fk_unit_buy
        );
        if (i >= 0)
          this.units_list.push({
            id: this.units[i].id,
            name: this.units[i].name,
          });
      }
    },
    get_categories() {
      this.categorie_list = [];
      if (this.products_categories) {
        this.categorie_list = this.products_categories.filter(
          (elm) => elm.produit_id == this.editedItem.produit_id
        );
        if (this.categorie_list.length == 1 && this.editedItem.id < 0)
          this.editedItem.categorie_id = this.categorie_list[0].categorie_id;
      }
    },
    OpenFilterForm() {
      this.isFilterClosed = false;
    },

    qte_change() {
      if (this.tiertype == 1 || this.tiertype == 3 || this.tiertype == 5) {
        let k = this.tier_type_prix.findIndex(
          (elm) =>
            elm.produit_id == this.editedItem.produit_id &&
            elm.tier_type_id == this.item.tier_type_id
        );

        if (k == -1) {
          let x = this.qte_prix.findIndex(
            (elm) =>
              elm.produit_id == this.editedItem.produit_id &&
              elm.qte_from <= this.editedItem.qte &&
              (elm.qte_to ? elm.qte_to >= this.editedItem.qte : true)
          );
          if (x >= 0) {
            if (this.qte_prix[x].prix > 0)
              this.editedItem.pu = this.qte_prix[x].prix;
          } else if (this.prixvente && !this.editedItem.pu)
            this.editedItem.pu = this.prixvente;
        }
      }
      this.editedItem.qte2 = 0;
      if (this.editedItem.fk_unit != this.editedItem.fk_unit_product) {
        let i = this.units_list.findIndex(
          (elm) => elm.id == this.editedItem.fk_unit
        );
        let cat_buy;
        let cat_unit;
        if (i >= 0) cat_buy = this.units[i].category_id;
        let j = this.units_list.findIndex(
          (elm) => elm.id == this.editedItem.fk_unit_product
        );
        if (j >= 0) cat_unit = this.units[j].category_id;
        if (cat_buy == cat_unit) {
          let factor_unit_buy = this.units_list[i].factor
            ? this.units_list[i].factor
            : 1;
          let factor_unit_product = this.units_list[j].factor
            ? this.units_list[j].factor
            : 0;
          this.editedItem.factor = factor_unit_product / factor_unit_buy;
        }
        this.editedItem.qte2 = this.editedItem.qte * this.editedItem.factor;
        this.editedItem.unit = this.units_list[j].name;
      }
    },

    close() {
      this.$emit("close");
    },
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          this.$store.dispatch("Changed", true);
          this.progress = false;
          r = data.data;
          this.close();
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save() {
      if (this.$refs.form.validate()) {
        let v;
        let ok = true;

        if (ok) {
          this.save_disable = true;
          if (this.editedItem.id > 0) {
            v = {
              input: {
                id: this.editedItem.id,
                pu: parseFloat(this.editedItem.pu),
                qte: parseFloat(this.editedItem.qte),
                produit_id: this.editedItem.produit_id,
                dem_det_id: this.editedItem.dem_det_id,
                delai: parseInt(this.editedItem.delai),
                adress_id: this.editedItem.adress_id,
                description: this.editedItem.description,
                article_id: this.editedItem.article_id,
                categorie_id: this.editedItem.categorie_id,
                tva_tx: parseFloat(this.editedItem.tva_tx),
                fk_unit: this.editedItem.fk_unit,
                comment: this.editedItem.comment,
                write_uid: this.$store.state.me.id,
              },
            };

            await this.maj(UPDATE_PRODUIT, v);
            this.$emit("change", this.editedItem);
          } else {
            v = {
              input: {
                pu: parseFloat(this.editedItem.pu),
                qte: parseFloat(this.editedItem.qte),
                produit_id: this.editedItem.produit_id,
                dem_det_id: this.editedItem.dem_det_id,
                delai: parseInt(this.editedItem.delai),
                adress_id: this.editedItem.adress_id,

                description: this.editedItem.description,
                article_id: this.editedItem.article_id,
                categorie_id: this.editedItem.categorie_id,
                tva_tx: parseFloat(this.editedItem.tva_tx),
                fk_unit: this.editedItem.fk_unit,
                comment: this.editedItem.comment,
                create_uid: this.$store.state.me.id,
                write_uid: this.$store.state.me.id,
              },
            };
            v.input.cmd_id = this.item.cmd_id;
            let r = null;
            r = await this.maj(CREATE_PRODUIT, v);
            if (r) {
              this.editedItem.id = r.createCmdDetail.id;
              this.$emit("add", this.editedItem);
            }
          }
          this.save_disable = false;
        }
      }
    },
  },
};
</script>
